import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import PrivateComponent from 'navigation/PrivateComponent';
import FullScreenWrapper from 'components/FullScreenWrapper';
import Link from 'components/Link';
import SummaryDataContainer from 'components/SummaryDataContainer';
import ChartUp from 'assets/svgs/chart_up.svg';
import MentalHealthChart from 'assets/svgs/mental_health_chart.svg';
import LightBulb from 'assets/svgs/light.svg';
import Text from 'components/Text';
import Header from 'components/Header';
import SummaryContentBlock from 'components/SummaryContentBlock';
import PersonalizedSummary from 'features/PersonalizedSummary';
import { BREAKPOINTS, ROUTES } from 'utils/constants';
import { handlePageView } from 'features/Tracking/trackingApiRequests';

const StyledWrapper = styled(FullScreenWrapper)({
  background:
    'linear-gradient(180deg, #0C4C7A -1.35%, #386A8C 15.61%, #4D7895 27.1%, #4D7895 53.45%, #3B4D70 103.49%)',
  [BREAKPOINTS[500]]: {
    padding: 0,
  },
});

const FirstBlock = styled(SummaryContentBlock)({
  marginTop: 132,
  padding: '160px 0 48px 0',
  [BREAKPOINTS[500]]: {
    margin: 0,
    paddingTop: 220,
  },
});

const LastBlock = styled(SummaryContentBlock)({
  marginBottom: 132,
  paddingBottom: 0,
  [BREAKPOINTS[500]]: {
    margin: 0,
  },
});

const StyledMentalHealthChart = styled(MentalHealthChart)({
  width: '100%',
  padding: 0,
  [BREAKPOINTS[500]]: {
    width: '100%',
  },
});

const ChartContent = styled.div({
  padding: '0 55px',
  [BREAKPOINTS[500]]: {
    padding: '0 20px',
  },
});

const Title = styled(Text)({
  fontWeight: 700,
  lineHeight: '36px',
  padding: '0 40px',
});

const FirstTitle = styled(Title)({
  marginBottom: 38,
});

const SecondTitle = styled(Title)({
  marginBottom: 24,
});

const MetricContainer = styled(SummaryDataContainer)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PercentageContainer = styled.div({
  width: 80,
  height: 80,
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  borderRadius: 40,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const PercentageContent = styled.div({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 8,
});

const PercentageText = styled(Text)({
  fontSize: 45,
  lineHeight: '67px',
  fontWeight: 700,
  marginLeft: 4,
  [BREAKPOINTS[440]]: {
    fontSize: 36,
  },
});

const MetricsText = styled(Text)({
  width: 158,
  lineHeight: '23px',
});

const Description = styled(Text)({
  fontWeight: 400,
  lineHeight: '22px',
  opacity: 0.6,
  padding: '18px 40px 22px 40px',
});

const StyledLightBulb = styled(LightBulb)({
  paddingRight: 8,
});

const StyledStartNowLink = styled(Link)({
  width: '100%',
  maxWidth: 180,
  marginBottom: 48,
});

const Summary: React.FC = () => {
  useEffect(() => {
    handlePageView('/summary');
  }, []);

  return (
    <PrivateComponent>
      <Header isVisible isStartNowButtonVisible />
      <StyledWrapper>
        <FirstBlock>
          <PersonalizedSummary />
        </FirstBlock>
        <LastBlock>
          <ChartContent>
            <FirstTitle type="h1" align="center">
              See what is waiting for you...
            </FirstTitle>
            <StyledMentalHealthChart />
          </ChartContent>
          <SecondTitle type="h1" align="center">
            Invest in your well-being with &ldquo;Yours App&ldquo;
          </SecondTitle>
          <MetricContainer>
            <PercentageContainer>
              <PercentageContent>
                <ChartUp />
                <PercentageText>93%</PercentageText>
              </PercentageContent>
            </PercentageContainer>
            <MetricsText>expressed higher quality sleep</MetricsText>
          </MetricContainer>
          <MetricContainer>
            <PercentageContainer>
              <PercentageContent>
                <ChartUp />
                <PercentageText>91%</PercentageText>
              </PercentageContent>
            </PercentageContainer>
            <MetricsText>said their anxiety levels have dropped</MetricsText>
          </MetricContainer>
          <MetricContainer>
            <PercentageContainer>
              <PercentageContent>
                <ChartUp />
                <PercentageText>87%</PercentageText>
              </PercentageContent>
            </PercentageContainer>
            <MetricsText>improved mental health </MetricsText>
          </MetricContainer>
          <Description align="center">
            <StyledLightBulb />
            Based on survey from &ldquo;Yours App&ldquo; subscribers who used the app 5+ times per
            week.
          </Description>
          <StyledStartNowLink to={ROUTES.CHECKOUT}>Start now</StyledStartNowLink>
        </LastBlock>
      </StyledWrapper>
    </PrivateComponent>
  );
};

export default Summary;
