import styled from '@emotion/styled';

import { BREAKPOINTS } from 'utils/constants';

const SummaryDataContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.backgroundGray,
  boxShadow: '0px 4px 4px rgba(2, 2, 2, 0.25)',
  borderRadius: 8,
  margin: '5px 0',
  padding: '24px 20px',
  width: 'calc(100% - 112px)',
  zIndex: 3,
  [BREAKPOINTS[500]]: {
    width: 'calc(100% - 72px)',
  },
}));

export default SummaryDataContainer;
