import React from 'react';

import PersonalizedHypnosis from 'assets/svgs/personalized_hypnosis.svg';
import PersonalizedYoga from 'assets/svgs/personalized_yoga.svg';
import PersonalizedMeditation from 'assets/svgs/personalized_meditation.svg';
import PersonalizedStories from 'assets/svgs/personalized_stories.svg';
import PersonalizedLifeCoaching from 'assets/svgs/personalized_life_coaching.svg';
import PersonalizedSoundsOfNature from 'assets/svgs/personalized_sounds_of_nature.svg';
import PersonalizedMusic from 'assets/svgs/personalized_music.svg';
import ReduceStress from 'assets/svgs/reduce_stress.svg';
import SleepStories from 'assets/svgs/sleep_stories.svg';
import Exclusive from 'assets/svgs/exclusive.svg';
import Clock from 'assets/svgs/clock.svg';
import Refresh from 'assets/svgs/refresh.svg';
import RelieveStress from 'assets/pngs/relieve_stress.png';
import RestAndRelax from 'assets/pngs/rest_and_relax.png';
import LearnYoga from 'assets/pngs/learn_yoga.png';
import BeMoreEfficient from 'assets/pngs/be_more_efficient.png';
import SleepBetter from 'assets/pngs/sleep_better.png';

export interface IBenefit {
  icon: JSX.Element;
  text: string;
}

export const INCLUDED_BENEFITS: IBenefit[] = [
  {
    icon: <ReduceStress />,
    text: 'Reduce stress and anxiety, fall asleep and find inner peace effortlessly',
  },
  {
    icon: <SleepStories />,
    text: 'Hundreds of sleep stories, guided meditations, music & sounds of nature',
  },
  {
    icon: <Exclusive />,
    text: 'Exclusive courses by world’s top mindfulness, psychology and yoga coaches',
  },
  {
    icon: <Clock />,
    text: '70+ hours of music for sleep, relaxation and focus',
  },
  {
    icon: <Refresh />,
    text: 'New content every week',
  },
];

export const INTERESTED_IN_CONTENT_QUESTION_ID = '79cd49be-f4e2-42ea-96b3-34b19e4f1e1a';
export const CONTENT_LENGTH_QUESTION_ID = 'a61c7121-b2d3-4ad7-9594-c8b73982c981';
export const GOALS_QUESTION_ID = '3a5afd72-08d1-4260-a2c2-11efc226d6d0';

export const IMAGES_BY_GOAL = {
  '870be7d6-0b97-40c3-8cd1-afa81d786461': RestAndRelax,
  'fbc97ef3-6fe4-43b5-9f15-52b48bfdf197': BeMoreEfficient,
  '14932827-ccb1-4c28-8ab6-04f90f3e6434': RelieveStress,
  '432c2c69-5db7-41db-bff4-b015e69de7ce': LearnYoga,
  '3a1a815f-ae20-41fd-aaba-19b6c82f45b2': SleepBetter,
};

export const INTERESTED_IN_CONTENT = {
  'eb3c0ba9-1d97-41e5-bea9-4fa3bbc7c03d': {
    text: 'Meditation',
    icon: <PersonalizedMeditation />,
  },
  'd0d3cc7c-ca7d-4233-b1fa-485ea4089544': {
    text: 'Stories',
    icon: <PersonalizedStories />,
  },
  '4b162926-843f-415e-a142-1f918f5776ef': {
    text: 'Music',
    icon: <PersonalizedMusic />,
  },
  '0556cb60-9b00-4b5b-bc5b-5ae543bfa59b': {
    text: 'Yoga',
    icon: <PersonalizedYoga />,
  },
  '7411e74d-8555-4215-a049-9a2ab7d04686': {
    text: 'Life Coaching',
    icon: <PersonalizedLifeCoaching />,
  },
  '70cabcf5-2532-461e-a622-f8c5cc668cc8': {
    text: 'Sounds of Nature',
    icon: <PersonalizedSoundsOfNature />,
  },
  'a76dd420-6e4f-4270-b16b-87f675ad9cbf': {
    text: 'Hypnosis',
    icon: <PersonalizedHypnosis />,
  },
};

export const PERSONALIZED_CONTENT_LENGTH = {
  'a17e6d53-f299-44ac-9939-d329e58ed12d': 'Short (3-10 minutes) content',
  'da29d26c-c925-45a6-9bfb-1e5d37eb8c02': 'Medium (10-20 minutes) content',
  '4db60bab-177f-454d-bea8-e4d8673c2870': 'Long (20+ minutes) content',
};
