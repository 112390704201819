import React from 'react';
import styled from '@emotion/styled';

import {
  INCLUDED_BENEFITS,
  INTERESTED_IN_CONTENT,
  INTERESTED_IN_CONTENT_QUESTION_ID,
  PERSONALIZED_CONTENT_LENGTH,
  CONTENT_LENGTH_QUESTION_ID,
  GOALS_QUESTION_ID,
  IMAGES_BY_GOAL,
  IBenefit,
} from './data';

import { useUserAnswersContext } from 'state/UserAnswersStateProvider';
import SummaryMountains from 'assets/pngs/summary_mountains.jpg';
import MobilePhone from 'assets/pngs/mobile_phone.png';
import SandClock from 'assets/svgs/sand_clock.svg';
import SummaryDataContainer from 'components/SummaryDataContainer';
import Link from 'components/Link';
import Text from 'components/Text';
import { BREAKPOINTS } from 'utils/constants';
import { BenefitContainer, BenefitIconContainer } from 'features/Checkout/Benefits';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#324D5E',
  [BREAKPOINTS[500]]: {
    backgroundColor: '#061218',
  },
});

const Title = styled(Text)({
  marginBottom: 24,
  fontWeight: 500,
  lineHeight: '32px',
});

const BenefitText = styled(Text)((props) => ({
  color: props.theme.colors.grayText,
  lineHeight: '22px',
  opacity: 0.9,
}));

const Description = styled(Text)({
  fontSize: 13,
  lineHeight: '19px',
  fontWeight: 400,
  opacity: 0.9,
  marginBottom: 18,
});

const Phone = styled.img({
  width: 'calc(100% - 42px)',
  marginTop: -100,
  zIndex: 2,
  pointerEvents: 'none',
});

const GoalImage = styled.img({
  width: '100%',
  margin: '4px 0',
  pointerEvents: 'none',
});

const PhoneBackground = styled.div({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${SummaryMountains})`,
  position: 'absolute',
  height: 496,
  top: 0,
  left: 0,
  right: 0,
  zIndex: 0,
});

const StyledStartNowLink = styled(Link)({
  width: '100%',
  maxWidth: 180,
  margin: '13px 0 32px 0',
});

const PhoneBackgroundOverlay = styled.div({
  position: 'absolute',
  height: 496,
  top: 0,
  left: 0,
  right: 0,
  mixBlendMode: 'normal',
  zIndex: 1,
  background:
    'linear-gradient(180deg, rgba(6, 18, 24, 0.37) 20.15%, rgba(17, 33, 41, 0.525009) 56.34%, #324D5E 100%)',
  [BREAKPOINTS[500]]: {
    background:
      'linear-gradient(180deg, rgba(6, 18, 24, 0.34) 13.96%, rgba(6, 18, 24, 0.760045) 71.78%, #061218 103.14%)',
  },
});

const PersonalizedSummary: React.FC = () => {
  const { getAnswersByIdFromStorage } = useUserAnswersContext();
  const allAnswers = getAnswersByIdFromStorage();
  const lengthAnswers = allAnswers?.[CONTENT_LENGTH_QUESTION_ID];
  const interestedInAnswers = allAnswers?.[INTERESTED_IN_CONTENT_QUESTION_ID];
  const goalsAnswers = allAnswers?.[GOALS_QUESTION_ID];

  return (
    <Container>
      <Phone src={MobilePhone} />
      <PhoneBackground />
      <PhoneBackgroundOverlay />
      {!!goalsAnswers?.length && (
        <SummaryDataContainer>
          <Title type="h1">Based on your preferences</Title>
          <Description>
            We have personalized your content to focus more on the following topics:
          </Description>
          {goalsAnswers?.map((goal) => (
            <GoalImage key={goal} src={IMAGES_BY_GOAL[goal]} />
          ))}
        </SummaryDataContainer>
      )}
      {(!!interestedInAnswers?.length || !!lengthAnswers?.length) && (
        <>
          <SummaryDataContainer>
            <Title type="h1">Your content will include more of</Title>
            {interestedInAnswers?.map((interestedInAnswer) => (
              <BenefitContainer key={interestedInAnswer}>
                <BenefitIconContainer>
                  {INTERESTED_IN_CONTENT[interestedInAnswer]?.icon}
                </BenefitIconContainer>
                <BenefitText>{INTERESTED_IN_CONTENT[interestedInAnswer]?.text}</BenefitText>
              </BenefitContainer>
            ))}
            {lengthAnswers?.map((lengthAnswer) => (
              <BenefitContainer key={lengthAnswer}>
                <BenefitIconContainer>
                  <SandClock />
                </BenefitIconContainer>
                <BenefitText>{PERSONALIZED_CONTENT_LENGTH[lengthAnswer]}</BenefitText>
              </BenefitContainer>
            ))}
          </SummaryDataContainer>
          <StyledStartNowLink scrollToBottom>Start now</StyledStartNowLink>
        </>
      )}
      <SummaryDataContainer>
        <Title type="h1">Included in your membership</Title>
        {INCLUDED_BENEFITS.map((benefit: IBenefit) => (
          <BenefitContainer key={benefit.text}>
            <BenefitIconContainer>{benefit.icon}</BenefitIconContainer>
            <BenefitText>{benefit.text}</BenefitText>
          </BenefitContainer>
        ))}
      </SummaryDataContainer>
    </Container>
  );
};

export default PersonalizedSummary;
